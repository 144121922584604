<template>
    <div>
        <header-portrate />

    <div class="m-2">
       <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />
        <v-row :style="`direction:`+lang.ldir+`;margin-left:30px;margin-right:30px;`">
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.ldir+`;padding:5px 30px;text-align:`+lang.lalgin">
                <button id="multiply-button" :style="`direction:`+lang.dir" :class="`multiply-button-`+lang.lalgin" @click="_AddPurch()" v-b-toggle.add_addporch>
                    <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_purcahse}}</div>
                </button>
            </v-col>
            
        </v-row>
        
        <v-row :style="`direction:`+lang.dir+`;margin-left:30px;margin-right:30px;`">
            <v-data-table
                :headers="headers"
                :items="purchrows"
                :search="search"
                :items-per-page="10"
                class="elevation-1"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-center" style="width:100px">{{ row.item.bill_number }}</td>
                <td class="text-center" nowrap>{{ row.item.vendor }}</td>
                <td class="text-center">{{ row.item.bill_date }}</td>
                <td class="text-center">{{ row.item.total }}</td>
                <td class="text-center">{{ row.item.vat }}</td>
                <td class="text-center">{{ row.item.ftotal }}</td>
                <td class="text-center" style="width:70px;background:red;border-bottom:1px solid #FFF !important;">
                  <v-btn style="font-size:0.2em;background:red;width:70px;color:#FFF;height:25px !important;margin-right:5px;" @click="delItem(row.item)">{{lang.delete}}</v-btn>
                </td>
                <td class="text-center" style="width:70px;background:#000;border-bottom:1px solid #FFF !important;">
                  <v-btn style="font-size:0.2em;background:#000;width:70px;color:#FFF;height:25px !important;" @click="ViewItem(row.item)" v-b-toggle.viewPurch>
                      {{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i>
                </v-btn>
                </td>
              </tr>
            </template>
            <template slot="body.append">
                
                    <tr>
                        <th> </th>
                        <th> </th>
                        <th style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{lang.totals}}</th>
                        <th style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{ptotals}}</th>
                        <th style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{pvat}}</th>
                        <th style="text-align:center;font-size:1.2em;background:#ccc;font-weight:bold">{{pftotals}}</th>
                        <th></th>
                        <th></th>
                    </tr>
                
            </template>
            </v-data-table>
            <v-container style="direction:rtl">
                <div class="text-center pt-2" style="direction:rtl">
                <v-pagination
                    v-model="page"
                    value="10"
                    :length="pageCount"
                    style="direction:rtl"
                ></v-pagination>
                </div>
            </v-container>
        </v-row> 
        <Purchase ref="addPurch" />
    </div>
        <Footer />
        <ViewPurchase ref="viewMenow" />
    </div>
</template>

<script>
import axios from 'axios';
import Purchase from '../components/Purchase.vue'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ViewPurchase from '@/components/ViewPurchase.vue';
export default{
    components:{
        Purchase, breadCrumbs,TabsComp,
        HeaderPortrate,Footer,
        ViewPurchase
    },
    data() {
        return {
            id:0,
            active_tab:2,
            search: '',
            
            purchrows:[],
            sdate: '',
            edate: '',
            page: 1,
            pageCount:0,
        }
    },
    computed: {
       lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
            return [
                {
                    text: this.lang.invoiceno,
                    align: 'center',
                    sortable: true,
                    value: 'bill_number',
                },
                {
                    text: this.lang.vendor,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                },
                {
                    text: this.lang.inv_date,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                },
                {
                    text: this.lang.total,
                    align: 'center',
                    sortable: false,
                    value: 'total',
                },
                {
                    text: this.lang.vat,
                    align: 'center',
                    sortable: false,
                    value: 'vat',
                },
                {
                    text: this.lang.ftotal,
                    align: 'center',
                    sortable: false,
                    value: 'ftotal',
                },
                {
                    text: this.lang.delete,
                    align: 'center',
                    sortable: false,
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                }
            ]
        },
        tabs: function() {
            let x = [];
            x.push(
                {
                    index:0,name:this.lang.vendors,href:'/inventory/vendors',class:'mytab3',onlypur: false
                }
            );
            x.push(
                {
                    index:0,name:this.lang.purchase,href:'/inventory/purchase',class:'mytab',onlypur: true
                }
            );
            if(this.$store.state.group_id != 3){
               
                x.push({
                    index:1,name:this.lang.product_categories,href:'/inventory/products-cats', class: 'mytab6',onlypur: false
                }
                );
                x.push({
                    index:2,name:this.lang.products,href:'/inventory/products', class: 'mytab1',onlypur: false
                });
                x.push({
                    index:3,name:' الاكثر مبيعا',href:'/inventory/best-seller', class: 'mytab2',onlypur: false
                });
                x.push({
                    index:4,name:' الاقل مبيعا',href:'/inventory/lowest-selling', class: 'mytab3',onlypur: false
                });
                x.push({
                    index:5,name:'الاصناف المنتهية',href:'/inventory/finished', class: 'mytab4',onlypur: false
                })
                x.push({
                    index:5,name:'حركة صنف',href:'/inventory/itemtrans', class: 'mytab4',onlypur: false
                })
            }
            let r = [];
            for(let i=0;i<x.length;i++){
                if(this.$store.state.licenseType.onlySalesPurchase){
                    if(x[i].onlypur){
                        r.push(x[i])
                    }
                }
                else if(!x[i].onlypur && !this.$store.state.licenseType.onlySalesPurchase){
                    r.push(x[i])
                }
            }
            return r;
            
        },
        items: function() {
            return {
                    text: this.lang.purchase,
                    disabled: true,
                    to: '/finance/purchase',
                }
        },
        ptotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].total) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pvat: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].vat) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pftotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].ftotal) + parseFloat(x);
            }
            return this.$RoundNum(x);
        }
    },
    methods: {
        _AddPurch(){
            
            this.$refs.addPurch.vendorid = '';
            this.$refs.addPurch.bill_number ='';
            this.$refs.addPurch.modal = false;
            this.$refs.addPurch.vendor = '';
            this.$refs.addPurch.mobile = '';
            this.$refs.addPurch.bill_date = '';
            this.$refs.addPurch.vat_number = '';
            this.$refs.addPurch.vendor_address = '';
            this.$refs.addPurch.paytype = 1;
            this.$refs.addPurch.vattype = 2;
            this.$refs.addPurch.full_total =0;
            this.$refs.addPurch.full_vat =0;
            this.$refs.addPurch.full_ftotal =0;
            this.$refs.addPurch.invtype =1;
            this.$refs.addPurch.invtype =1;
            this.$refs.addPurch.cashajl = 1;
            this.$refs.addPurch.tbrows =[];
            this.$refs.addPurch.newrow = {
                item_number: '',
                item_name: '',
                unit: this.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            };
            this.$refs.addPurch.enableAdd();
        },
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.purchrows;
            const reportname = 'فواتير المشتريات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    if(myhead[i].text != 'الاجراء')
                        keys.push(myhead[i].value);
                }
            }
            
            const post = new FormData();
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        ViewItem(item){
            this.$refs.viewMenow.billid = item.id;
            this.$refs.viewMenow.getBill();
            
            //this.$router.push({path: '/view-purchase/'+item.id})
        },
        getInvoice(){
            const post = new FormData();
            post.append('type','getPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.id);
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[type]',1);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.purchrows = res.results.data;
            });
        },
        delItem(item)
        {
            const post = new FormData();
            post.append('type','delPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',item.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getInvoice();
            });
        }
    },
    created() {
        this.getInvoice();
    },
    
}
</script>